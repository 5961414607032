<template>
    <div class="staff-box">
        <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>员工管理</el-breadcrumb-item>
            <el-breadcrumb-item>部门设置</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="editRule">
                    <el-button @click="add" type="primary" size="small" icon="el-icon-plus">添加</el-button>
                </el-form-item>
                <el-form-item>
                     <el-input size="normal" :placeholder="this.CONST.PLACEHOLDER" v-model="key">
                        <template slot="prepend">部门</template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- list-department -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="department"
            style="width: 100%">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    prop="department"
                    label="名称">
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <el-button :disabled="!editRule" size="mini" plain :type="scope.row.status==0?'primary':'info'">
                                {{scope.row.status==0?'启用':'未启用'}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item in [0,1]" :key="item" @click.native="changeSatus(scope.row.did,item)">
                                {{item==0?'启用':'未启用'}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="editRule"
                width="100"
                fixed="right"
                label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.did)"></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页 -->
        <div class="block">
            <el-pagination
                 @current-change="changePage"
                layout="prev, pager, next"
                :page-size="pageSize"
                hide-on-single-page
                :total="totalPage">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import {checkRules} from '@/utils/util.js'
export default {
    data(){
        return{
            editRule:checkRules(15),//修改权限
            form:{},
            department:[
                {
                    name:"外勤部",
                    status:0
                },{
                    name:"客服部",
                    status:0
                },{
                    name:"管理部",
                    status:0
                }
            ],
            page:1,
            pageSize:10,
            key:'',
            totalPage:0,
            loading:false
        }
    },
    created(){
        //获取部门
        this.getDepartment()
    },
    methods:{
        //更改部门状态
        changeSatus(did,status){
            let params = {
                method:'updateDepartmentStatus',
                did,
                status
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(()=>{
                //console.log(res)
                this.loading = false 
                this.search()
            }).catch(err=>{
                console.log(err)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //添加部门
        add(){
            this.$prompt(this.CONST.INPUT_HINT_NAME, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消'
            }).then(({ value }) => {
                //console.log(value)
                this.addDepartment(value)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.CONST.DEL_CANCEL
                }); 
            });
        },
        addDepartment(department){
            let params = {
                method:'addDepartment',
                page:this.page,
                pageSize:this.pageSize,
                key:this.key,
                department
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false 
                if(data){
                    this.totalPage = data.totalPage 
                    this.department = data.list
                }
            }).catch(err=>{
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        handleDelete(did){
            this.$confirm(this.CONST.DEL_CONFIRM)
                .then(()=> {
                    let params = {
                    method:'delDepartment',
                    did
                }
                this.loading = true
                this.$ajax.post(this.API.api,params).then((res)=>{
                    this.loading = false 
                    this.$message({
                        message: res.msg||this.CONST.DEL_SUC,
                        type: 'success'
                    });
                    this.getDepartment()
                }).catch(err=>{
                    this.loading = false 
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
            }).catch(err=>{
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
        },
        //搜索部门
        search(){
            this.page = 1 
            this.totalPage = 0
            //刷新
            this.getDepartment()
        },
        //切换页码
        changePage(page){
            console.log(page)
            this.page = page 
            this.getDepartment()
        },
        //获取部门列表
        getDepartment(){
            let params = {
                method:'getDepartment',
                page:this.page,
                pageSize:this.pageSize,
                keys:this.key
            }
            console.log(params)
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                //todo
                //console.log(data)
                this.loading = false 
                if(data){
                    this.totalPage = data.totalPage 
                    this.department = data.list
                }
            }).catch(err=>{
                //err
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<style lang="less">
 .box-department{
    .header-bar{
        margin:10px 0;
    }
 }
</style>